import {
  addToCart,
  selectAStore,
  checkAvailability,
  partsAndServices,
  instantCheckout,
  protectionPlan
} from 'thd-cart-bindings/dist/thd-cart-bindings.amd';

const EVENT_BUS = window.THD_EVENT_BUS || window.LIFE_CYCLE_EVENT_BUS;

function getNormalizedChannel(channel) {
  if(!channel) {
    return 'desktop';
  }
  return channel.toLowerCase() === 'tablet' ? 'desktop': channel;
}

if (EVENT_BUS) {
  EVENT_BUS.on('cart.add-to-cart', function (data) {
    addToCart(data.output.cartReqParams, {
      ...data.output.cartOptions,
      channel: getNormalizedChannel(data.output.cartOptions.channel)
    })
      .on('success', (resp) => {
        EVENT_BUS.trigger('add-to-cart.success', resp);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('add-to-cart.error', err);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });

  EVENT_BUS.on('cart.select-store', function (data) {
    selectAStore({
      ...data.output.cartReqParams,
      channel: getNormalizedChannel(data.output.cartReqParams.channel)
    })
      .on('success', (resp) => {
        EVENT_BUS.trigger('select-a-store.success', resp);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('select-a-store.error', err);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });

  EVENT_BUS.on('cart.instant-checkout', function (data) {
    instantCheckout(data.output.cartReqParams, {
      ...data.output.cartOptions,
      channel: getNormalizedChannel(data.output.cartOptions.channel)
    })
      .on('success', (resp) => {
        EVENT_BUS.trigger('instant-checkout.success', resp);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('instant-checkout.success', err);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });

  EVENT_BUS.on('cart.addHDPP', function (data) {
    protectionPlan(data.output.cartReqParams)
      .on('success', (resp) => {
        EVENT_BUS.trigger('addHDPP.success', resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('addHDPP.error', err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });

  EVENT_BUS.on('cart.check-availability', function (data) {
    checkAvailability(data.output.cartReqParams)
      .on('success', (resp) => {
        EVENT_BUS.trigger('check-availability.success', resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('check-availability.error', err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });

  EVENT_BUS.on('cart.partsAndServices', function (data) {
    partsAndServices(data.output.cartReqParams)
      .on('success', (resp) => {
        EVENT_BUS.trigger('partsAndServices.success', resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('partsAndServices.error', err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });
}
